import React from 'react'
import { inject, observer } from 'mobx-react'
import { Controller, useForm } from 'react-hook-form'
import googlelibphonenumber from 'google-libphonenumber'
import styled from 'styled-components'
import { Textfit } from 'react-textfit'
import Grid from '@material-ui/core/Grid'
import TypographyPro from 'themes/TypographyPro'
import Skip from './Skip'
import { respondAbove } from 'styles/mixins'
import TextField from 'components/common/TextField'

import { SubmitStyledButton, SubmitButtonContainer, Form, TitleContainer, StyledGridContainer } from './Steps.styles'
import { getTranslatedTextByKey, isMobileApp } from 'utils/utils'

import type { AxiosError } from 'axios'
import type { InfraProps } from 'mobx/Infra/Infra.type'
import type { AccountInterface } from 'mobx/Account/Account.type'

const { PhoneNumberType } = googlelibphonenumber
const phoneUtil = googlelibphonenumber.PhoneNumberUtil.getInstance()

const TermsPrivacyAgreeStyledTypographyPro = styled(TypographyPro)`
	color: var(--footerAndDarkBackgrounds);
	text-align: start;

	a {
		margin: 0;
		line-height: 20px;
		text-decoration: underline;
		color: var(--footerAndDarkBackgrounds);
		${({ theme }) => theme.BodyRegularClickable};
	}
`

const SubTitleContainer = styled(TypographyPro).attrs({ variant: 'BodyRegularHighlighted' })`
	color: var(--strokeMenuTitle);
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 8px;
`

const TermsPrivacyAgreeStyledGrid = styled(Grid)`
	margin-top: 10px;
`

const StyledTypographyProButtonText = styled(TypographyPro)`
	height: 100%;
	max-width: 95%;

	${respondAbove.md`
		max-width: unset;
		width: 100%;
	`}
`

const TitleAndSubtitleContainer = styled.div`
	text-align: center;
`

const VerifyPhoneNumber = inject(
	'Account',
	'Infra'
)(
	observer(({ Account, Infra, onSkipClick }: { Account: AccountInterface; Infra: InfraProps; onSkipClick: any }) => {
		const { sendMeCode, privacyPolicy, termsConditions, defaultCountry } = Account
		const {
			handleSubmit,
			setError,
			reset,
			control,
			formState: { errors, isDirty, isValid },
		} = useForm({ mode: 'onChange', defaultValues: { phoneNumber: '' } })

		const onSubmit = async ({ phoneNumber }: { phoneNumber: string }) => {
			try {
				Infra.setLoading(true)
				await sendMeCode(phoneNumber)
			} catch (error: unknown) {
				console.log(error)
				// Errors regarding phone verification that come from the server are handled here
				const { code: errorResponseTextKey, message: defaultMessage } =
					(error as AxiosError<{ error: { code: string; message: string } }>).response?.data?.error || {}
				const keyElements = errorResponseTextKey?.split('.')
				setError('phoneNumber', {
					type: 'manual',
					message: getTranslatedTextByKey(`eCommerce.signIn[${keyElements?.[0]}][${keyElements?.[1]}]`, defaultMessage),
				})
			} finally {
				Infra.setLoading(false)
			}
		}

		const verifyPhoneNumber = (phoneNumber: string) => {
			try {
				const _phoneNumber = phoneUtil.parse(phoneNumber, defaultCountry)

				if (phoneUtil.isValidNumber(_phoneNumber)) {
					const _numberType = phoneUtil.getNumberType(_phoneNumber)

					if (
						_numberType === PhoneNumberType.MOBILE ||
						_numberType === PhoneNumberType.FIXED_LINE_OR_MOBILE ||
						(defaultCountry && ['MX', 'AR'].indexOf(defaultCountry) > -1 && _numberType === PhoneNumberType.FIXED_LINE)
					) {
						return true
					}
					return getTranslatedTextByKey('eCommerce.signIn.invalidMobileNumber')
				}
				return getTranslatedTextByKey('eCommerce.signIn.invalidMobileNumber')
			} catch (e: any) {
				const errorToTranslationMap: Record<string, string> = {
					'The string supplied is too short to be a phone number': 'eCommerce.signIn.phoneVerification.tooShort',
					'Phone number too short after IDD': 'eCommerce.signIn.phoneVerification.tooShortIDD',
					'The string supplied is too long to be a phone number': 'eCommerce.signIn.phoneVerification.tooLong',
					'The string supplied was too long to parse': 'eCommerce.signIn.phoneVerification.tooLongToParse',
					'The string supplied did not seem to be a phone number': 'eCommerce.signIn.phoneVerification.notAPhone',
					'The phone number supplied was null': 'eCommerce.signIn.phoneVerification.phoneIsNull',
					'Could not interpret numbers after plus-sign': 'eCommerce.signIn.phoneVerification.notInterpretable',
					'Invalid country calling code': 'eCommerce.signIn.phoneVerification.invalidCountryCode',
					'Missing or invalid default region': 'eCommerce.signIn.phoneVerification.invalidDefaultRegion',
					'Too many requests at the last minute': 'eCommerce.signIn.phoneVerification.tooManyRequests',
				}

				const key = errorToTranslationMap[e.message]

				if (!key) {
					return e.message
				}

				if (e.message in errorToTranslationMap) {
					return getTranslatedTextByKey(key, e.message)
				}
			}
		}

		return (
			<StyledGridContainer>
				<TitleAndSubtitleContainer>
					<TitleContainer container direction="row">
						<TypographyPro variant="h3">{Account.signupEditPhoneNumberTitle}</TypographyPro>
					</TitleContainer>
					{Account.signupEditPhoneNumberSubTitle && (
						<SubTitleContainer variant="BodyRegular">{Account.signupEditPhoneNumberSubTitle}</SubTitleContainer>
					)}
				</TitleAndSubtitleContainer>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Grid container direction="row">
						<Controller
							name="phoneNumber"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									onChange={onChange}
									value={value}
									autoFocus
									id="sign-page-phone-input"
									autoComplete="off"
									onResetValue={(val) => reset(val as unknown as { phoneNumber: string })}
									error={Object.prototype.hasOwnProperty.call(errors, 'phoneNumber')}
									errorMessage={
										errors.phoneNumber?.message ||
										getTranslatedTextByKey('webviewFlow.phoneValidationErrorPhoneNumber', 'Please enter a valid number')
									}
									label={`${getTranslatedTextByKey('eCommerce.signIn.phoneNumber')} *`}
									inputProps={{
										'data-testid': 'sign-in-phone-field',
									}}
									type="tel"
								/>
							)}
							rules={{
								required: true,
								validate: verifyPhoneNumber,
							}}
						/>
					</Grid>
					<TermsPrivacyAgreeStyledGrid container direction="row">
						<TermsPrivacyAgreeStyledTypographyPro variant="BodyRegular">
							{getTranslatedTextByKey('eCommerce.signIn.agreeText')}{' '}
							<a href={privacyPolicy} target={isMobileApp() ? '_self' : 'privacy'}>
								{getTranslatedTextByKey('webviewFlow.privacyPolicy')}
							</a>{' '}
							{getTranslatedTextByKey('and')}{' '}
							<a href={termsConditions} target={isMobileApp() ? '_self' : 'terms'}>
								{getTranslatedTextByKey('webviewFlow.termsAndConditions')}
							</a>
						</TermsPrivacyAgreeStyledTypographyPro>
					</TermsPrivacyAgreeStyledGrid>
					<SubmitButtonContainer container direction="row">
						<SubmitStyledButton
							$size="big"
							disabled={!isDirty || !isValid}
							type="submit"
							id="sign-page-sign-up-button"
							data-testid="account-submit-button"
						>
							<StyledTypographyProButtonText component="div" variant="CTABig">
								<Textfit mode="single" forceSingleModeWidth={false}>
									{getTranslatedTextByKey('eCommerce.signIn.sendMeACode')}
								</Textfit>
							</StyledTypographyProButtonText>
						</SubmitStyledButton>
					</SubmitButtonContainer>
				</Form>
				{Account.signupDisplaySkipSection && (
					<Skip
						caption={getTranslatedTextByKey('eCommerce.signIn.skip')}
						onSkip={onSkipClick}
						or={getTranslatedTextByKey('eCommerce.signIn.or')}
					/>
				)}
			</StyledGridContainer>
		)
	})
)

export default VerifyPhoneNumber
