// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { SESSION_EXPIRED, CONSTANTS, EXTERNAL_ADDRESSBOX_ID } from 'utils/constants'
import { laptop, respondAbove } from 'styles/mixins'
import queryString from 'query-string'
import ImageComponent from 'themes/ImageComponent'
import styled, { css } from 'styled-components/macro'
import Infra from 'mobx/Infra'
import Payment from 'mobx/Payment/Payment'
import Application from 'mobx/Application'
import TypographyPro from 'themes/TypographyPro'
import AddressBoxLoader from '../../common/placeholder/AddressBoxLoader'
import ItemPopup from '../ItemPopup'
import LocalizedHeader from 'components/header/headers/LocalizedHeaderBar/LocalizedHeader'
import { useHistory, useLocation } from 'react-router-dom'
import CloseIcon, { CloseIconItemPupopTop } from 'components/common/CloseIcon'
import TitleAndAddressSearch from 'components/home/NewHomePageHeader/TitleAndAddressSearch'
import { isNextJS } from '../../../../utils/nextUtils'
import { isEmpty } from 'lodash-es'
import Cookies from 'js-cookie'
import nextCookiesState from '../../../../utils/nextCookiesState'
import { getTranslatedTextByKey } from 'utils/utils'
import { useClientSideRendering } from 'hooks/useClientSideRendering'
import { sendCustomEvent } from 'utils/analytics/analytics'
import { FeatureFlagEnum } from 'types/constants.types'

const Container = styled.div`
	box-shadow: var(--defaultShadow1);
	width: 100%;
	z-index: 5;
	border-radius: 0 0 10px 10px;
	position: sticky;
	top: 48px;
	@media ${laptop} {
		min-height: 201px;
		display: flex;
	}

	${({ $titleHeight }) =>
		css`
			top: -${$titleHeight - 45}px;

			${respondAbove.md`
				top: -${$titleHeight - 70}px;
			`}
		`}

	${respondAbove.xl`
		display: flex;
		background: var(--clear);
		justify-content: center;
		top: 83px;	
	`}

	${({ $backdropEnabled }) =>
		$backdropEnabled &&
		css`
			z-index: 1203;
			border-radius: 10px;
		`}

	${({ $isStoreClosed }) =>
		$isStoreClosed &&
		css`
			cursor: not-allowed;
			& * &:not(${styled(LocalizedHeader)}) {
				pointer-events: none;
			}
		`}

	${({ $itemPopupTop }) =>
		$itemPopupTop &&
		css`
			border-radius: var(--borderRadiusFrame);
		`}
`

const ContainerOrderAndTitle = styled.div`
	display: flex;
	flex-direction: column;
	background: var(--clear);
	align-items: center;
	width: 100%;
	position: relative;
	border-radius: 0 0 10px 10px;

	${({ $backdropEnabled }) => $backdropEnabled && `background-color: rgba(0, 0, 0, 0.6);`}

	${respondAbove.md`
		z-index: 1202;
		background: var(--clear);
	`}

	${respondAbove.lg`
		flex-direction: row;
		z-index: 1203;
		${({ $backdropEnabled, $itemPopupTop }) =>
			$backdropEnabled ? ($itemPopupTop ? `border-radius: var(--borderRadiusFrame);` : `border-radius: 10px;`) : ''}
	`}

	${respondAbove.xl`
		padding-left: unset;
		padding-right: unset;
		max-width: 1280px;
	`}

	${({ $itemPopupTop }) => $itemPopupTop && `border-radius: var(--borderRadiusFrame);`}
`

const OrderTypeAndLocationContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	//padding: 25px 20px 20px 20px;
	z-index: 1203;
	//background: var(--clear);
	border-radius: 0px 0px 10px 10px;

	${respondAbove.md`
		border-radius: unset;
		padding-top: unset;
		${({ $itemPopupTop }) => `margin-top: ${$itemPopupTop ? '0' : '25px'};`}
	`}

	${respondAbove.lg`
		flex: 1;
		padding: unset;
		padding-right: ${({ $itemPopupTop }) => `${$itemPopupTop ? '0' : '40px'}`};
		margin-top: unset;
		padding-left: ${({ $itemPopupTop }) => `${$itemPopupTop ? '0' : '40px'}`};
	`}

	${({ $backdropEnabled }) =>
		$backdropEnabled &&
		`
		border-radius: 10px;
	`}

	
	${({ isStoreClosed }) =>
		isStoreClosed &&
		css`
			cursor: not-allowed;
			& * {
				pointer-events: none;
			}
		`}
`

const HomepageHeaderTitle = styled(TypographyPro)`
	text-align: center;
`
const HomepageHeaderSubTitle = styled(TypographyPro)`
	text-align: center;
`

const TitleContainer = styled.div`
	color: var(--strokeMenuTitle);
	box-shadow: var(--highlightShadow1);
	padding: 25px 20px 20px 20px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	width: 100%;
	background: var(--clear);
	z-index: 1300;
	${({ $backdropEnabled }) => $backdropEnabled && `opacity: 0.1;`}

	${respondAbove.md`
		opacity:1;
	`}

	${respondAbove.lg`
		width: unset;
		flex: 1;
		box-shadow: unset;
		z-index: 1203;
		padding: unset;
		padding-right: 40px;
		padding-left: 40px;
	`}
`

// TODO: currently we have two address boxes with a lot of common code in the future we will need refactor this
const NewHeaderLayout = inject(
	'Payment',
	'Infra',
	'User',
	'Home',
	'AddressManager'
)(
	observer((props) => {
		const { Home, isGeneratedStatically, User, AddressManager, page } = props
		const { branchesList, clickedFeaturedItem, backdropEnabled } = Home
		const addressBoxContainerRef = useRef()
		const titleContainerRef = useRef()
		const isStoreClosed = Home.areAllStoresClosed()
		const [isPopupOpen, setIsPopupOpen] = useState(false)
		const history = useHistory()
		const [showLocalizedAddress, setShowLocalizedAddress] = useState(false)
		const [showCloseIconForLocalization, setShowCloseIconForLocalization] = useState(false)
		const isClientSideRendering = useClientSideRendering()
		const itemPopupTop = Infra?.hasFeatureFlag(FeatureFlagEnum.ADDRESS_BOX_POPUP_TOP)

		useEffect(() => {
			if (clickedFeaturedItem) {
				document.documentElement.scrollIntoView({ behavior: 'smooth' })
			}
		}, [clickedFeaturedItem])

		useEffect(() => {
			if (branchesList.msg) {
				if (!branchesList.msg?.deliveryStores && !branchesList.msg?.disablePickup) {
					User.setOrderType(CONSTANTS.DELIVERY_METHODS.PICKUP)
				} else if (branchesList.msg?.deliveryStores && branchesList.msg?.disablePickup) {
					User.setOrderType(CONSTANTS.DELIVERY_METHODS.DELIVERY)
				}
			}
		}, [branchesList.msg])

		useEffect(() => {
			Infra.setLoading(true)
			setShowLocalizedAddress(AddressManager.isUserLocalized())
			Application.fetchAndUpdateHomePageData(Infra, Home)
		}, [])

		useEffect(() => {
			const isSessionExpired = sessionStorage.getItem(SESSION_EXPIRED) || nextCookiesState.fromClient.get(SESSION_EXPIRED, Cookies)
			if (isSessionExpired === 'true') {
				sessionStorage.removeItem(SESSION_EXPIRED)
				nextCookiesState.fromClient.remove(SESSION_EXPIRED, Cookies)
				const title = 'Session Expired'
				Infra.setNotification({
					// TODO: need translation for this
					title,
					okAction: () => Infra.closeNotification(),
				})

				sendCustomEvent({
					category: 'error',
					action: 'notification',
					label: title,
				})
			}
		})

		useEffect(
			() => {
				const parsed = queryString.parse(window.location.search)
				if (parsed.modifyLocalizedAddress) {
					handleLocalizedAddress()
					setShowCloseIconForLocalization(true)
				}
			},
			// TODO FOR NEXT: need to improve this part, because on nextJS, it will run after each re-render
			// and not after each location changes
			// (possible solution https://nextjs.org/docs/pages/api-reference/functions/use-router#routerbeforepopstate)
			isNextJS() || isGeneratedStatically ? null : [useLocation()]
		)

		const onContainerClicked = (event) => {
			if (isStoreClosed) {
				const title = getTranslatedTextByKey('webviewFlow.storesAreClosed', 'sorry all our stores are currently closed')
				const message = getTranslatedTextByKey('webviewFlow.orderMessage', 'Please order during our working hours')
				Infra.setNotification({
					open: true,
					title,
					message,
					topImg: <ImageComponent asset="/images/pastOrdersEmpty.png" />,
					customAction: () => {
						Infra.closeNotification()
					},
					customButtonType: 'secondary',
					cancelText: getTranslatedTextByKey('menu.backToMenu', 'Back to menu'),
					showCancelButton: false,
					closeAction: true,
					cancelAction: () => {
						Infra.closeNotification()
					},
				})

				sendCustomEvent({
					category: 'error',
					action: 'notification',
					label: title,
					message,
				})
			}
		}

		const toggleItemPopup = (show) => {
			setIsPopupOpen(show)

			if (!showLocalizedAddress) {
				setShowCloseIconForLocalization(false)
			}
		}

		const handleLocalizedAddressClose = () => {
			Home.setBackdropEnabled(false)
			setShowLocalizedAddress(true)
			setShowCloseIconForLocalization(false)
		}

		const handleClickOnCloseIcon = () => {
			setShowLocalizedAddress(AddressManager.isUserLocalized())
			Home.setBackdropEnabled(false)
			const localizedOrderType = AddressManager.getLocalizedOrderType()
			if (localizedOrderType) {
				User.setOrderType(localizedOrderType)
				if (isGeneratedStatically || isNextJS() || Infra?.appParams?.features?.staticPagesWithAddressBox?.includes(page)) {
					handleLocalizedAddressClose()
					return
				}

				history.goBack()
			}
		}

		const handleLocalizedAddress = () => {
			Home.setBackdropEnabled(true)
			setShowLocalizedAddress(false)
			setShowCloseIconForLocalization(true)
		}

		return (
			<Container
				id={EXTERNAL_ADDRESSBOX_ID}
				ref={addressBoxContainerRef}
				$backdropEnabled={!!backdropEnabled}
				$titleHeight={titleContainerRef?.current?.clientHeight || 0}
				onClick={onContainerClicked}
				$isStoreClosed={isStoreClosed}
				$itemPopupTop={itemPopupTop}
			>
				{!itemPopupTop && isClientSideRendering && Home.branchesList.msg && (
					<ItemPopup newAddressBox toggleItemPopup={toggleItemPopup} isPopupOpen={isPopupOpen} />
				)}

				<ContainerOrderAndTitle $backdropEnabled={!!backdropEnabled} $itemPopupTop={itemPopupTop}>
					{!isEmpty(Home.branchesList.msg) && !isEmpty(Home.locale) ? (
						<OrderTypeAndLocationContainer $backdropEnabled={!!backdropEnabled} $itemPopupTop={itemPopupTop}>
							{showLocalizedAddress ? (
								<LocalizedHeader
									isGeneratedStatically={isGeneratedStatically || isNextJS()}
									page={page}
									handleLocalizedAddress={handleLocalizedAddress}
								/>
							) : (
								<TitleAndAddressSearch
									isPopupOpen={isPopupOpen}
									isGeneratedStatically={isGeneratedStatically}
									backdropEnabled={backdropEnabled}
									titleContainerRef={titleContainerRef}
									isStoreClosed={isStoreClosed}
									isClientSideRendering={isClientSideRendering}
									itemPopupTop={itemPopupTop}
									toggleItemPopup={toggleItemPopup}
									page={page}
									setShowLocalizedAddress={setShowLocalizedAddress}
								/>
							)}
						</OrderTypeAndLocationContainer>
					) : (
						<AddressBoxLoader width={500} height="100%" />
					)}
				</ContainerOrderAndTitle>
				{showCloseIconForLocalization &&
					(itemPopupTop ? (
						<CloseIconItemPupopTop testId="localized-close-icon" onClick={handleClickOnCloseIcon} $show={!showLocalizedAddress} />
					) : (
						<CloseIcon testId="localized-close-icon" $show={!showLocalizedAddress} onClick={handleClickOnCloseIcon} />
					))}
			</Container>
		)
	})
)

export default NewHeaderLayout
