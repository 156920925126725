// @ts-nocheck
import React, { useEffect, useState } from 'react'
import DialogBox from '../components/common/DialogBox'
import useWindowState from 'hooks/useWindowState'
import { inject, observer } from 'mobx-react'
import { isEmpty } from 'lodash-es'

/**
 * withDialog HOC.
 *
 * @Component: the Component to render
 expose props:
 initDialog(config: object): function to initial the dialog, by passing an object:
 {
	 	title: Dialog's title
	 	message: message as the dialog content
		okText: text for the "ok" button
	 	cancelText: text for the "cancel" button
	 	okAction: a callback invoked for the ok button
	 	cancelAction: a callback invoked for the ok button
 }
 isDialogOpen: a boolean for the dialog's state (open/close)
 openDialog(): function to open the dialog
 closeDialog(): function to close the dialog

 How to use:

 call "withDialog()" in your component for example:
 export default withDialog(MyComponent)

 now you'll have an access to the props above in your component (MyComponent).
 call "initialDialog()" with an object inside "useEffect()" for example, in order to initial the dialog props.
 for example:

 useEffect(
 ...
 props.initDialog({
	 	title: Dialog's title
	 	message: message as the dialog content
		okText: text for the "ok" button
	 	cancelText: text for the "cancel" button
	 	okAction: a callback invoked for the ok button
	 	cancelAction: a callback invoked for the ok button
 })
 ...
 , [])

 Open the dialog everywhere it needs to be opened with "openDialog()"
 Close the dialog everywhere it needs to be opened with "closeDialog()"

 */
export const withDialog = (Component) =>
	inject(
		'Infra',
		'Account'
	)(
		observer((props) => {
			const [isDialogOpen, setDialogOpen] = useState(false)
			const [addWindowState, activateBackState] = useWindowState()
			const { Infra, Account } = props

			useEffect(() => {
				if (!isEmpty(Infra.notification)) {
					Infra.openNotification()
					addWindowState(closeViaDefaultBackBtn)
				}
			}, [Infra.notification])

			useEffect(() => {
				// check "Infra.triggerCloseNotification",
				// if it's true - activateBackState(true) to pop from the stack
				if (Infra.triggerCloseNotification) {
					activateBackState(true)
					Infra.setTriggerNotification(false)
				}
			}, [Infra.triggerCloseNotification])

			const closeViaDefaultBackBtn = () => {
				Infra.notification = {}
			}

			const closeDialog = () => {
				setDialogOpen(false)
				activateBackState(true)
				if (Infra?.notification && Infra?.notification?.onClose && typeof Infra.notification.onClose === 'function') {
					Infra.notification.onClose()
				}
			}

			return (
				<>
					<Component {...props} isDialogOpen={isDialogOpen} />
					<DialogBox {...Infra.notification} onClose={closeDialog} style={{ display: Account.signUpPopUp ? 'none' : 'block' }} />
				</>
			)
		})
	)
