import { getLocaleStr, getTranslatedTextByKey } from 'utils/utils'
import TypographyPro from 'themes/TypographyPro'
import React from 'react'
import styled from 'styled-components/macro'
import type { CouponListItem } from 'components/common/coupons/types'

const CouponTitle = styled(TypographyPro)`
	color: var(--fonts);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const CouponAmount = styled(CouponTitle)`
	color: var(--fonts);
`

type CouponItemRenderProps = Omit<CouponListItem, 'code'>

const CouponItemRow = ({ status, amount, amountLeftToGetDiscount, coupon, locale }: CouponItemRenderProps) =>
	status === 'success' ? (
		<>
			{amount ? <CouponAmount variant="BodySmallHighlighted">{amount}</CouponAmount> : null}
			<CouponTitle variant="BodySmall">{getLocaleStr(coupon.title, locale)}</CouponTitle>
		</>
	) : (
		<>
			{getTranslatedTextByKey('webviewFlow.coupons.spendAnother', 'Spend another')} {amountLeftToGetDiscount}{' '}
			{getTranslatedTextByKey('webviewFlow.coupons.toActive', 'to activate')}{' '}
			<TypographyPro component="span" variant="BodySmallHighlighted">
				"{getLocaleStr(coupon.title, locale)}"
			</TypographyPro>{' '}
			{getTranslatedTextByKey('webviewFlow.coupons.coupon', 'coupon')}!
		</>
	)

export default CouponItemRow
