import React from 'react'
import OrderTypes from './OrderTypes'
import AddressSearch from './AddressSearch'
import styled, { css } from 'styled-components/macro'
import { respondAbove, respondTo } from 'styles/mixins'

const OrderTypeAndLocationContainer = styled.div<{ $popupOpen: boolean; isStoreClosed: boolean; $itemPopupTop: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 15px 20px 10px 20px;
	z-index: 1203;
	background: var(--clear);
	border-radius: 0px 0px 10px 10px;

	${respondAbove.md`
		margin-top: 25px;
		padding-top: unset;
	`}

	${respondAbove.lg<{ $popupOpen: boolean; isStoreClosed: boolean; $itemPopupTop: boolean }>`
		flex: 1;
		padding: unset;
		padding: ${({ $itemPopupTop }) => `${$itemPopupTop ? '20px 40px' : '0 40px'}`};
		margin-top: unset;
	`}

	${respondTo.md<{ $itemPopupTop: boolean }>`
		${({ $itemPopupTop }) =>
			$itemPopupTop &&
			css`
				border-top-right-radius: 0;
				border-top-left-radius: 0;
				border-bottom-right-radius: var(--borderRadiusFrame);
				border-bottom-left-radius: var(--borderRadiusFrame);
			`}
	`}

	${({ $popupOpen }) => $popupOpen && `border-radius: 10px;`}

	
	${({ isStoreClosed }) =>
		isStoreClosed &&
		css`
			cursor: not-allowed;
			& * {
				pointer-events: none;
			}
		`}
`

interface Props {
	backdropEnabled: boolean
	isPopupOpen: boolean
	isGeneratedStatically: boolean
	isStoreClosed: boolean
	itemPopupTop: boolean
	setShowLocalizedAddress: (show: boolean) => void
}

const NewAddressBox = (props: Props) => {
	const { backdropEnabled, isPopupOpen, isGeneratedStatically, isStoreClosed, itemPopupTop, setShowLocalizedAddress } = props

	return (
		<OrderTypeAndLocationContainer $popupOpen={!!backdropEnabled} $itemPopupTop={itemPopupTop} isStoreClosed={isStoreClosed}>
			<OrderTypes />
			<AddressSearch
				setShowLocalizedAddress={setShowLocalizedAddress}
				isGeneratedStatically={isGeneratedStatically}
				isPopupOpen={isPopupOpen}
				backdropEnabled={!!backdropEnabled}
			/>
		</OrderTypeAndLocationContainer>
	)
}

export default NewAddressBox
