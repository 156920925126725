// @ts-nocheck
import React, { useState, useEffect, useContext, useRef } from 'react'
import { isNumber } from 'lodash-es'
import { useHistory } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import {
	formatPrice,
	getTranslatedTextByKey,
	isIPhoneX,
	isMobile,
	setItemsAPI,
	getDomainByEnv,
	getStore,
	initOrUpdateSession,
	getStoreName,
} from 'utils/utils'
import { finish } from 'utils/chatAppUtils'
import { StoreContext } from 'contexts/StoreContext'
import queryString from 'query-string'
import ShareWithFriendForm from '../menu/ShareWithFriendForm'
import styled, { css } from 'styled-components/macro'
import { sendEnhancedEcommerceEvent, sendCustomEvent, getRestaurantAnalyticsFields, getCartAnalyticsFields } from '../../utils/analytics/analytics'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import useSnackbar from 'hooks/useSnackbar'
import ButtonBase from 'components/common/ButtonBase'
import { isNextJS } from '../../../utils/nextUtils'

const EEE = CONSTANTS.ANALYTICS.ENHANCED_ECOMMERCE_EVENTS

const CheckOutButton = styled(ButtonBase)`
	width: 100%;
	display: flex;
	justify-content: space-between;
`

const CheckOutButtonContainer = styled.div`
	z-index: 2;
	position: fixed;
	width: ${({ $fullWidth }) => ($fullWidth ? 100 : 25)}%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	transition-property: bottom;
	transition-duration: 100ms;
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
	backdrop-filter: blur(8px);
	-webkit-backdrop-filter: blur(8px);
	padding: 0px 10px;
	bottom: 7px;

	${({ $menu }) =>
		$menu &&
		css`
			// if browser is not supporting backdrop-filter use fallback solution
			@supports not ((-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px))) {
				background: rgba(255, 255, 255, 0.9);
			}
		`};

	@media (max-width: 576px) {
		padding: 20px;
		align-items: center;
		bottom: ${({ $mobileApp }) => ($mobileApp ? 65 : 0)}px;
		width: 100%;
	}

	${({ $isIPhoneX }) =>
		$isIPhoneX &&
		css`
			height: 115px;
			padding-bottom: 23px;
		`}
`

/**
 * Only seen on chat apps so the user can always checkout of the web-app and exit back to Messenger or WA
 *
 * @type {(function(*): *) & function(*): * extends IReactComponent<infer P> ? IWrappedComponent<P> : never}
 */
export const CheckoutButton = inject(
	'Infra',
	'User',
	'Cart',
	'Application',
	'Payment',
	'Home'
)(
	observer((props) => {
		const { Infra } = props
		const { Application } = props
		const { store, nextJS } = useContext(StoreContext)
		const rest = store.data
		const { Cart } = props
		const { User } = props
		const { Home } = props
		const { Payment } = props
		const { isMenu } = props
		const [buttonDisabled, setButtonDisabled] = useState(props.calcNumberOfItems === 0)
		const checkoutBtnRef = useRef(null)
		const history = useHistory()

		useSnackbar(checkoutBtnRef, 'checkout-btn')

		const loadMenuAndInitSession = async (storeId, existingMenuPath) => {
			try {
				const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
				const { wru = getDomainByEnv() } = Infra.appParams

				// if menuUrl exists in localstoage, we use it instead of starting a new session
				const newMenuPath =
					existingMenuPath ??
					(await initOrUpdateSession({
						storeId,
						refObject: { orderType },
						stopLoading: false,
						shouldRedirectIfError: false,
					}))

				if (newMenuPath) {
					const newMenuQueryParams = queryString.parse((newMenuPath ?? '').split('?')[1])
					storeId = process.env.NODE_ENV === 'production' ? storeId : `${storeId}`

					const storeMetaData = await getStore({
						wru,
						request: newMenuQueryParams.request,
						cust: newMenuQueryParams.cust,
						tictuk_listener: newMenuQueryParams.tictuk_listener,
					})

					return {
						metaData: storeMetaData,
						newMenuPath,
					}
				}
				return null
			} catch (error) {
				console.log(error)
			} finally {
				Infra.setLoading(false)
			}
		}

		const submitHandler = (formData) => {
			Infra.closeNotification()
			finish(null, props.cartItems, rest.items, true, formData.name)
		}

		const checkout = async () => {
			const parsed = queryString.parse(window.location.search)

			if (parsed.shared) {
				// the OK button is provided by the <ShareWithFriendForm/> component since it can get the name from
				// the input and the outer DialogBox cannot so easily.
				Infra.setNotification({
					title: '',
					message: <ShareWithFriendForm closeForm={submitHandler} inviter={parsed.firstName} />,
				})
			} else if (
				[
					CONSTANTS.APP.TYPES.WEB.toString(),
					CONSTANTS.APP.TYPES.WEB_MOBILE.toString(),
					CONSTANTS.APP.TYPES.ANDROID_APP.toString(),
					CONSTANTS.APP.TYPES.IOS_APP.toString(),
				].includes(parsed?.app || User.session.appid.toString())
			) {
				const isCurrentPageStatic = window.location.href.includes('.html')

				// Used to get the previous menu page url to extract query parameters
				let existingMenuPath = null

				// call check_field...setItems which adds the cart to the server sync. AND checks if it has reached the min order
				if (!store.metaData || !window.location.pathname.includes('menu') || isCurrentPageStatic) {
					const metaDataAndMenuPath = await loadMenuAndInitSession(localStorage.getItem('storeId'), localStorage.getItem('menuUrl'))
					store.metaData = metaDataAndMenuPath.metaData
					existingMenuPath = metaDataAndMenuPath.newMenuPath
				}

				const setItemsResponse = await setItemsAPI(Cart, rest)
				if (!setItemsResponse) {
					return
				}

				sendCustomEvent({
					category: 'menu',
					action: 'button pressed',
					label: 'checkout',
				})

				const deliveryType = isNumber(props?.checkoutDeliveryType)
					? props?.checkoutDeliveryType
					: User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY
					? ORDER_TYPES.DELIVERY
					: ORDER_TYPES.PICKUP

				const orderType = User.getOrderType()

				sendEnhancedEcommerceEvent(
					EEE.sendCheckOutEvent,
					{ ...getRestaurantAnalyticsFields(rest), storeName: getStoreName(store, orderType, Home) },
					{
						step: CONSTANTS.CHECKOUT_STEPS.GOTO_CHECKOUT_PAGE,
						deliveryType,
						paymentType: Payment.paymentMethod,
						storeID: store?.data?.id || '',
						storeName: getStoreName(store) || '',
					},
					getCartAnalyticsFields(Cart)
				)

				const locationSearch = (() => {
					if (!existingMenuPath && window.location.search) {
						return window.location.search
					}
					if (existingMenuPath) {
						const params = existingMenuPath.split('?')
						params.shift()
						return `?${params.join('')}`
					}
					throw new Error('No menu identified')
				})()

				if (isNextJS()) {
					Infra.setLoading(true)
					nextJS.router.push(`/checkout${locationSearch}`)
				} else if (isCurrentPageStatic) {
					window.location.href = `${window.location.origin}/checkout${locationSearch}`
				} else {
					history.push(`/checkout${locationSearch}`)
				}
			} else {
				// show loader while calling /webview API
				finish(null, props.cartItems, rest.items, true)
			}
		}

		useEffect(() => {
			const _activateButton = parseInt(props.serverGrandTotal) > 0 || props.calcNumberOfItems > 0
			setButtonDisabled(!_activateButton)
		}, [props.serverGrandTotal, props.calcNumberOfItems])

		const checkoutComponent = (
			<CheckOutButtonContainer
				$mobileApp={Application.isMobileApp}
				id="checkoutButton"
				$isIPhoneX={isIPhoneX()}
				$menu={isMenu}
				ref={isMobile() ? checkoutBtnRef : null}
				$fullWidth={props.fullWidth}
			>
				<CheckOutButton
					data-testid="checkout-button"
					onClick={checkout}
					disabled={buttonDisabled}
					id="checkoutButton"
					$menu={isMenu}
					$size="big"
					fullWidth
				>
					<span>{getTranslatedTextByKey('btns.moveOn')}</span>
					<span data-testid="total-price">{formatPrice(props.serverGrandTotal, rest?.currency || 'USD', rest?.countryCode || 'us')}</span>
				</CheckOutButton>
			</CheckOutButtonContainer>
		)

		return checkoutComponent
	})
)

export default CheckoutButton
